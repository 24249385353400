.rcw-conversation-container > .rcw-header {
  background-color: #2B718C;
}
.rcw-conversation-container {
    box-shadow: 0 2px 10px 1px #0000007a;
}
.rcw-client > .rcw-message-text {
    background-color: #30ABAB;
}

.rcw-launcher {
    background-color: #2B718C;
    box-shadow: 0 2px 10px 1px #0000007a;
}